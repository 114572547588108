export const startErrorTimer = (systemId, setMachines, setErrorTimers) => {
  setErrorTimers(prev => ({
    ...prev,
    [systemId]: setTimeout(() => {
      setMachines(prevMachines => prevMachines.map(machine => 
        machine.system_id === systemId 
          ? { ...machine, power_state: 'contact_support' } 
          : machine
      ));
      setErrorTimers(prev => {
        const newTimers = { ...prev };
        delete newTimers[systemId];
        return newTimers;
      });
    }, 60000)
  }));
};
