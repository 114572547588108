import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';

const GalaxyLoader = ({ opacity = 1 }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, composer;
    let galaxy;
    let clock = new THREE.Clock();

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 80;

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x000000, 1);
      renderer.setPixelRatio(window.devicePixelRatio);
      canvasRef.current.appendChild(renderer.domElement);

      composer = new EffectComposer(renderer);
      composer.addPass(new RenderPass(scene, camera));
      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.8, 0.3, 0.1
      );
      composer.addPass(bloomPass);

      galaxy = createGalaxy(100000, 40, new THREE.Color(0x6C3EFF));

      scene.add(galaxy);

      const ambientLight = new THREE.AmbientLight(0x222222);
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0x6C3EFF, 1, 1000);
      pointLight.position.set(0, 0, 50);
      scene.add(pointLight);
    };

    const createGalaxy = (numParticles, radius, color) => {
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(numParticles * 3);
      const colors = new Float32Array(numParticles * 3);
      const sizes = new Float32Array(numParticles);
      const opacities = new Float32Array(numParticles);

      for (let i = 0; i < numParticles; i++) {
        const theta = Math.random() * Math.PI * 2;
        const phi = Math.acos(2 * Math.random() - 1);
        const r = Math.random() * radius;

        positions[i * 3] = r * Math.sin(phi) * Math.cos(theta);
        positions[i * 3 + 1] = r * Math.sin(phi) * Math.sin(theta);
        positions[i * 3 + 2] = r * Math.cos(phi);

        const mixedColor = color.clone().lerp(new THREE.Color(0xffffff), Math.random() * 0.2);
        colors[i * 3] = mixedColor.r;
        colors[i * 3 + 1] = mixedColor.g;
        colors[i * 3 + 2] = mixedColor.b;

        sizes[i] = Math.random() * 2 + 0.5;
        opacities[i] = Math.random() * 0.5 + 0.5;
      }

      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
      geometry.setAttribute('opacity', new THREE.BufferAttribute(opacities, 1));

      const material = new THREE.ShaderMaterial({
        vertexShader: `
          attribute float size;
          attribute float opacity;
          varying vec3 vColor;
          varying float vOpacity;
          uniform float time;
          void main() {
            vColor = color;
            vOpacity = opacity;
            vec3 pos = position;
            pos.x += sin(time * 0.5 + pos.z * 0.02) * 2.0;
            pos.y += cos(time * 0.5 + pos.x * 0.02) * 2.0;
            pos.z += sin(time * 0.3 + pos.y * 0.02) * 2.0;
            vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
            gl_Position = projectionMatrix * mvPosition;
            float distance = length(mvPosition.xyz);
            gl_PointSize = size * (300.0 / distance);
            vOpacity *= smoothstep(0.0, 50.0, distance);
          }
        `,
        fragmentShader: `
          varying vec3 vColor;
          varying float vOpacity;
          void main() {
            float r = distance(gl_PointCoord, vec2(0.5));
            if (r > 0.5) discard;
            gl_FragColor = vec4(vColor, vOpacity * (1.0 - smoothstep(0.3, 0.5, r)));
          }
        `,
        transparent: true,
        vertexColors: true,
        uniforms: {
          time: { value: 0 }
        }
      });

      return new THREE.Points(geometry, material);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      const time = clock.getElapsedTime();

      galaxy.material.uniforms.time.value = time;
      galaxy.rotation.y += 0.0005;

      camera.position.x = Math.sin(time * 0.1) * 5;
      camera.position.y = Math.cos(time * 0.1) * 5;
      camera.lookAt(scene.position);

      composer.render();
    };

    init();
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      composer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };
  }, []);

  return (
    <div className="fixed inset-0" style={{ opacity }}>
      <div ref={canvasRef} className="w-full h-full"></div>
    </div>
  );
};

export default GalaxyLoader;
