import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Alert } from './components/ui/alert';
import ApiKeyForm from './ApiKeyForm';
import MachineCard from './MachineCard';
import Header from './Header';
import { fetchMachines, handlePowerAction, queryPowerState } from './api';
import { startErrorTimer } from './utils';
import GalaxyLoader from './GalaxyLoader';

const PowerControlUI = () => {
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pendingActions, setPendingActions] = useState({});
  const [errorTimers, setErrorTimers] = useState({});
  const [titleKey, setTitleKey] = useState(0);
  const [apiKey, setApiKey] = useState('');
  const [isApiKeySet, setIsApiKeySet] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);

  const isProduction = process.env.REACT_APP_ENV === 'production';

  const handleError = useCallback((errorMessage) => {
    if (errorMessage.includes('ngrok') || errorMessage.includes('Tunnel') || errorMessage.includes('404 Not Found')) {
      console.error('Connection error:', errorMessage);
      if (!isProduction) {
        setError('Connection error. Please check your network and try again.');
      }
    } else {
      console.error(errorMessage);
      if (!isProduction) {
        setError(errorMessage);
      }
    }
  }, [isProduction]);

  const fetchMachinesCallback = useCallback(async (isSync = false) => {
    if (!isApiKeySet) return;
    setLoading(true);
    setError(null);
    try {
      const data = await fetchMachines(apiKey, '', isSync);
      setMachines(prevMachines => {
        const updatedMachines = Array.isArray(data) ? data
          .filter(machine => machine.status_name === "Deployed")
          .map(newMachine => {
            const prevMachine = prevMachines.find(m => m.system_id === newMachine.system_id);
            if (prevMachine && prevMachine.power_state === 'pending') {
              return { ...newMachine, power_state: 'pending' };
            }
            if (newMachine.power_state === 'error' && !errorTimers[newMachine.system_id]) {
              startErrorTimer(newMachine.system_id, setMachines, setErrorTimers);
            }
            return newMachine;
          }) : [];

        return updatedMachines.sort((a, b) => a.hostname.localeCompare(b.hostname));
      });
      setTitleKey(prevKey => prevKey + 1);
    } catch (error) {
      handleError(`Failed to fetch machines. ${error.message}`);
    } finally {
      setLoading(false);
      setOverlayVisible(true);
    }
  }, [apiKey, isApiKeySet, errorTimers, handleError]);

  const pollPowerStatus = useCallback(async (systemId, action) => {
    const expectedStatus = action === 'power_on' ? 'on' : 'off';
    const pollInterval = setInterval(async () => {
      try {
        const powerState = await queryPowerState(apiKey, systemId);
        if (powerState.state === expectedStatus) {
          clearInterval(pollInterval);
          setPendingActions(prev => {
            const newPending = { ...prev };
            delete newPending[systemId];
            return newPending;
          });
          setMachines(prev => {
            const updatedMachines = prev.map(machine =>
              machine.system_id === systemId ? { ...machine, power_state: expectedStatus } : machine
            );
            return updatedMachines.sort((a, b) => a.hostname.localeCompare(b.hostname));
          });
        }
      } catch (error) {
        handleError(`Error polling machine status: ${error.message}`);
        clearInterval(pollInterval);
        setPendingActions(prev => {
          const newPending = { ...prev };
          delete newPending[systemId];
          return newPending;
        });
        setMachines(prev => prev.map(machine =>
          machine.system_id === systemId
            ? { ...machine, power_state: 'error' }
            : machine
        ));
        startErrorTimer(systemId, setMachines, setErrorTimers);
      }
    }, 5000);
  }, [apiKey, handleError]);

  const handlePowerActionCallback = useCallback(async (systemId, action) => {
    setError(null);
    setPendingActions(prev => ({ ...prev, [systemId]: action }));
    setMachines(prev => prev.map(machine =>
      machine.system_id === systemId
        ? { ...machine, power_state: 'pending' }
        : machine
    ));
    try {
      await handlePowerAction(apiKey, systemId, action);
      console.log(`${action} action successful for machine ${systemId}`);

      pollPowerStatus(systemId, action);
    } catch (error) {
      handleError(`Failed to ${action} machine. ${error.message}`);
      setPendingActions(prev => {
        const newPending = { ...prev };
        delete newPending[systemId];
        return newPending;
      });
      setMachines(prev => prev.map(machine =>
        machine.system_id === systemId
          ? { ...machine, power_state: 'error' }
          : machine
      ));
      startErrorTimer(systemId, setMachines, setErrorTimers);
    }
  }, [apiKey, handleError, pollPowerStatus]);

  useEffect(() => {
    const storedApiKey = localStorage.getItem('maasApiKey');
    if (storedApiKey) {
      setApiKey(storedApiKey);
      setIsApiKeySet(true);
      fetchMachinesCallback();
    } else {
      setLoading(false);
    }
    const interval = setInterval(() => fetchMachinesCallback(), 30000);
    return () => {
      clearInterval(interval);
      Object.values(errorTimers).forEach(clearTimeout);
    };
  }, [fetchMachinesCallback, errorTimers]);

  const handleLogout = () => {
    localStorage.removeItem('maasApiKey');
    setApiKey('');
    setIsApiKeySet(false);
    setMachines([]);
    setOverlayVisible(false);
  };

  if (!isApiKeySet) {
    return (
      <ApiKeyForm
        apiKey={apiKey}
        setApiKey={setApiKey}
        setIsApiKeySet={setIsApiKeySet}
        fetchMachines={fetchMachinesCallback}
        error={error}
        setError={setError}
      />
    );
  }

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <GalaxyLoader />
      <div 
        className={`absolute inset-0 bg-black transition-opacity duration-3000 ease-in-out ${overlayVisible ? 'opacity-60' : 'opacity-0'}`}
      ></div>
      <div className="absolute inset-0 overflow-auto">
        <div className="p-6 min-h-screen">
          <div className="max-w-7xl mx-auto">
            <Header
              titleKey={titleKey}
              handleLogout={handleLogout}
              fetchMachines={() => fetchMachinesCallback(true)}
            />
            {!isProduction && error && (
              <Alert variant="destructive" className="mb-6 bg-red-900 border border-red-700 text-red-100">
                <AlertTriangle className="inline-block mr-2 h-5 w-5" />
                <span>{error}</span>
              </Alert>
            )}
            {loading && machines.length === 0 ? (
              <div className="text-center mt-10 text-white">
                <p></p>
              </div>
            ) : machines.length === 0 ? (
              <div className="text-center mt-10 text-white">
                <p>No machines available.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                {machines.map(machine => (
                  <MachineCard
                    key={machine.system_id}
                    machine={machine}
                    handlePowerAction={handlePowerActionCallback}
                    pendingActions={pendingActions}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerControlUI;
