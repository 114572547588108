import React from 'react';
import PowerControlUI from './PowerControlUI';

function App() {
  return (
      <div className="App">
        <PowerControlUI />
      </div>
  );
}

export default App;
