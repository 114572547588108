import React, { useState } from 'react';
import { Power, Server, Cpu, HardDrive, Network, MemoryStick, X, Loader, Info } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './components/ui/card';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from './components/ui/alert-dialog';

const MachineCard = ({ machine, handlePowerAction, pendingActions }) => {
  const [showSpecification, setShowSpecification] = useState(false);
  const totalStorage = machine.blockdevice_set.reduce((sum, device) => sum + device.size, 0);
  const storageInTB = totalStorage / (1024 * 1024 * 1024 * 1024); // Convert to TB
  const isPending = pendingActions[machine.system_id] || machine.power_state === 'pending';
  const isError = machine.power_state === 'error' || machine.power_state === 'contact_support';
  const isOn = machine.power_state === 'on';

  const IconButton = ({ action, icon: Icon, color, onClick, isDisabled }) => (
    <button
      onClick={onClick}
      disabled={isDisabled || isPending}
      className={`relative w-8 h-8 rounded-full overflow-hidden ${isDisabled || isPending ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'}`}
    >
      <div className="absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
      <div className="relative z-10 flex items-center justify-center w-full h-full">
        {isPending && pendingActions[machine.system_id] === action ? (
          <Loader className="h-4 w-4 animate-spin text-white" />
        ) : (
          <Icon className={`h-4 w-4 ${color}`} />
        )}
      </div>
    </button>
  );

  return (
    <Card className={`
      bg-gray-800 bg-opacity-40 backdrop-filter backdrop-blur-sm
      shadow-lg hover:shadow-xl transition-all duration-300 card-animate
      ${isOn ? 'bg-purple-500 bg-opacity-15 border-purple-500 border-opacity-50' :
        isError ? 'bg-yellow-500 bg-opacity-15 border-yellow-500 border-opacity-50' :
        'border-gray-700'}
      relative border-2
    `}>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="flex items-center">
          <Server className="h-6 w-6 mr-3 text-white icon-animate" />
          <span className="text-lg font-semibold text-white truncate" title={`${machine.hostname}${machine.pool?.name ? ` [${machine.pool.name}]` : ''}`}>
            {machine.hostname}
            {machine.pool?.name && <span className="text-sm text-gray-300 ml-1">[{machine.pool.name}]</span>}
          </span>
        </CardTitle>
        <div className="flex items-center space-x-2">
          <IconButton
            action="power_on"
            icon={Power}
            color="text-green-400"
            onClick={() => handlePowerAction(machine.system_id, 'power_on')}
            isDisabled={machine.power_state === 'on' || isError}
          />
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <IconButton
                action="power_off"
                icon={Power}
                color="text-red-400"
                isDisabled={machine.power_state === 'off' || isError}
              />
            </AlertDialogTrigger>
            <AlertDialogContent className="bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-md border border-gray-700 text-white">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-white">Are you sure you want to power off this machine?</AlertDialogTitle>
                <AlertDialogDescription className="text-gray-300">
                  This action will shut down {machine.hostname}. Any running processes will be terminated.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel className="bg-transparent border border-gray-600 text-white hover:bg-gray-700">Cancel</AlertDialogCancel>
                <AlertDialogAction 
                  onClick={() => handlePowerAction(machine.system_id, 'power_off')}
                  className="bg-red-600 text-white hover:bg-red-700"
                >
                  Power Off
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <IconButton
            icon={Info}
            color="text-white"
            onClick={() => setShowSpecification(true)}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2 text-gray-200">
          <p className="flex items-center justify-between">
            <span className="font-semibold">Status:</span>
            {isPending ? (
              <span className="flex items-center">
                <Loader className="h-4 w-4 mr-2 animate-spin" />
                Pending
              </span>
            ) : machine.power_state === 'contact_support' ? (
              <a href="mailto:support@elementalcompute.com" className="font-bold ml-2 text-yellow-300">
                Contact Support
              </a>
            ) : (
              <span className={`font-bold ml-2 ${
                machine.power_state === 'on' ? 'text-green-400' :
                machine.power_state === 'off' ? 'text-red-400' :
                'text-yellow-300'
              }`}>
                {machine.power_state.toUpperCase()}
              </span>
            )}
          </p>
          <p className="flex items-center justify-between">
            <span className="font-semibold">Vendor:</span>
            <span>{machine.hardware_info?.system_vendor || 'N/A'}</span>
          </p>
        </div>
      </CardContent>

      {showSpecification && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-95 z-10 p-4 overflow-auto machine-spec-overlay">
          <button
            onClick={() => setShowSpecification(false)}
            className="absolute top-2 right-2 bg-gray-800 bg-opacity-50 text-white hover:bg-opacity-70 rounded-full p-1"
          >
            <X className="h-4 w-4" />
          </button>
          <h3 className="text-sm font-semibold mb-4 text-white">Machine Specification</h3>
          <div className="space-y-2 text-sm text-gray-200">
            <p className="flex items-center justify-between">
              <span className="flex items-center font-semibold">
                <Cpu className="h-4 w-4 mr-2 text-gray-300 icon-animate" />
                CPU:
              </span>
              <span>{machine.cpu_count || 'N/A'} cores @ {(machine.cpu_speed / 1000).toFixed(2) || 'N/A'} GHz</span>
            </p>
            <p className="flex items-center justify-between">
              <span className="flex items-center font-semibold">
                <MemoryStick className="h-4 w-4 mr-2 text-gray-300 icon-animate" />
                Memory:
              </span>
              <span>{((machine.memory || 0) / 1024).toFixed(2)} GB</span>
            </p>
            <p className="flex items-center justify-between">
              <span className="flex items-center font-semibold">
                <HardDrive className="h-4 w-4 mr-2 text-gray-300 icon-animate" />
                Storage:
              </span>
              <span>{storageInTB.toFixed(2)} TB</span>
            </p>
            <p className="flex items-center justify-between">
              <span className="flex items-center font-semibold">
                <Network className="h-4 w-4 mr-2 text-gray-300 icon-animate" />
                Network:
              </span>
              <span>{(machine.boot_interface?.link_speed / 1000).toFixed(2) || 'N/A'} Gbps</span>
            </p>
          </div>
        </div>
      )}
    </Card>
  );
};

export default MachineCard;
