import React, { useEffect, useRef } from 'react';
import { Key, AlertTriangle } from 'lucide-react';
import { Input } from './components/ui/input';
import { Alert } from './components/ui/alert';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';

const GlowingButton = ({ children, ...props }) => {
  return (
    <button
      className="relative w-full bg-gray-900 text-white border border-gray-700 py-1.5 px-3 rounded-md overflow-hidden group text-sm"
      {...props}
    >
      <span className="relative z-10">{children}</span>
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-700 to-gray-900 opacity-50 group-hover:opacity-100 animate-glow"></div>
      </div>
    </button>
  );
};

const ApiKeyForm = ({
  apiKey,
  setApiKey,
  setIsApiKeySet,
  fetchMachines,
  error,
  setError
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let scene, camera, renderer, composer;
    let galaxies, connections, stars;
    let clock = new THREE.Clock();

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 2000);
      camera.position.z = 50;

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      canvasRef.current.appendChild(renderer.domElement);

      composer = new EffectComposer(renderer);
      composer.addPass(new RenderPass(scene, camera));
      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        0.8, 0.3, 0.85
      );
      bloomPass.threshold = 0;
      bloomPass.strength = 1.5;
      bloomPass.radius = 0.8;
      composer.addPass(bloomPass);

      galaxies = new THREE.Group();
      connections = new THREE.Group();
      stars = new THREE.Group();

      const createGalaxy = (numParticles, radius, color, conceptName) => {
        const geometry = new THREE.BufferGeometry();
        const positions = new Float32Array(numParticles * 3);
        const colors = new Float32Array(numParticles * 3);
        const sizes = new Float32Array(numParticles);
        const opacities = new Float32Array(numParticles);

        for (let i = 0; i < numParticles; i++) {
          const theta = Math.random() * Math.PI * 2;
          const phi = Math.acos(2 * Math.random() - 1);
          const r = Math.random() * radius;

          positions[i * 3] = r * Math.sin(phi) * Math.cos(theta);
          positions[i * 3 + 1] = r * Math.sin(phi) * Math.sin(theta);
          positions[i * 3 + 2] = r * Math.cos(phi);

          const mixedColor = color.clone().lerp(new THREE.Color(0xffffff), Math.random() * 0.2);
          colors[i * 3] = mixedColor.r;
          colors[i * 3 + 1] = mixedColor.g;
          colors[i * 3 + 2] = mixedColor.b;

          sizes[i] = Math.random() * 2 + 0.5;
          opacities[i] = Math.random() * 0.5 + 0.5;
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
        geometry.setAttribute('opacity', new THREE.BufferAttribute(opacities, 1));

        const material = new THREE.ShaderMaterial({
          vertexShader: `
            attribute float size;
            attribute float opacity;
            varying vec3 vColor;
            varying float vOpacity;
            uniform float time;
            void main() {
              vColor = color;
              vOpacity = opacity;
              vec3 pos = position;
              pos.x += sin(time * 0.5 + pos.z * 0.02) * 2.0;
              pos.y += cos(time * 0.5 + pos.x * 0.02) * 2.0;
              pos.z += sin(time * 0.3 + pos.y * 0.02) * 2.0;
              vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
              gl_Position = projectionMatrix * mvPosition;
              float distance = length(mvPosition.xyz);
              gl_PointSize = size * (300.0 / distance);
              vOpacity *= smoothstep(0.0, 50.0, distance);
            }
          `,
          fragmentShader: `
            varying vec3 vColor;
            varying float vOpacity;
            void main() {
              float r = distance(gl_PointCoord, vec2(0.5));
              if (r > 0.5) discard;
              gl_FragColor = vec4(vColor, vOpacity * (1.0 - smoothstep(0.3, 0.5, r)));
            }
          `,
          transparent: true,
          vertexColors: true,
          uniforms: {
            time: { value: 0 }
          }
        });

        const points = new THREE.Points(geometry, material);
        points.userData.conceptName = conceptName;
        return points;
      };

      const createConnections = (galaxy1, galaxy2, numConnections) => {
        const geometry = new THREE.BufferGeometry();
        const positions = new Float32Array(numConnections * 6);
        const colors = new Float32Array(numConnections * 6);
        const opacities = new Float32Array(numConnections * 2);

        for (let i = 0; i < numConnections; i++) {
          const index1 = Math.floor(Math.random() * galaxy1.geometry.attributes.position.count);
          const index2 = Math.floor(Math.random() * galaxy2.geometry.attributes.position.count);

          const start = new THREE.Vector3(
            galaxy1.geometry.attributes.position.array[index1 * 3],
            galaxy1.geometry.attributes.position.array[index1 * 3 + 1],
            galaxy1.geometry.attributes.position.array[index1 * 3 + 2]
          ).add(galaxy1.position);

          const end = new THREE.Vector3(
            galaxy2.geometry.attributes.position.array[index2 * 3],
            galaxy2.geometry.attributes.position.array[index2 * 3 + 1],
            galaxy2.geometry.attributes.position.array[index2 * 3 + 2]
          ).add(galaxy2.position);

          positions[i * 6] = start.x;
          positions[i * 6 + 1] = start.y;
          positions[i * 6 + 2] = start.z;
          positions[i * 6 + 3] = end.x;
          positions[i * 6 + 4] = end.y;
          positions[i * 6 + 5] = end.z;

          const color = new THREE.Color(0xffffff);
          color.setHSL(Math.random(), 0.5, 0.5);

          colors[i * 6] = color.r;
          colors[i * 6 + 1] = color.g;
          colors[i * 6 + 2] = color.b;
          colors[i * 6 + 3] = color.r;
          colors[i * 6 + 4] = color.g;
          colors[i * 6 + 5] = color.b;

          opacities[i * 2] = Math.random() * 0.1;
          opacities[i * 2 + 1] = Math.random() * 0.1;
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        geometry.setAttribute('opacity', new THREE.BufferAttribute(opacities, 1));

        const material = new THREE.ShaderMaterial({
          vertexShader: `
            attribute float opacity;
            varying vec3 vColor;
            varying float vOpacity;
            void main() {
              vColor = color;
              vOpacity = opacity;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
          fragmentShader: `
            varying vec3 vColor;
            varying float vOpacity;
            uniform float time;
            void main() {
              float alpha = vOpacity * (sin(time * 2.0 + gl_FragCoord.x * 0.1) * 0.5 + 0.5) * 0.1;
              gl_FragColor = vec4(vColor, alpha);
            }
          `,
          transparent: true,
          uniforms: {
            time: { value: 0 }
          }
        });

        return new THREE.LineSegments(geometry, material);
      };

      const createStars = (numStars) => {
        const geometry = new THREE.BufferGeometry();
        const positions = new Float32Array(numStars * 3);
        const sizes = new Float32Array(numStars);

        for (let i = 0; i < numStars; i++) {
          positions[i * 3] = (Math.random() - 0.5) * 2000;
          positions[i * 3 + 1] = (Math.random() - 0.5) * 2000;
          positions[i * 3 + 2] = (Math.random() - 0.5) * 2000;
          sizes[i] = Math.random() * 2 + 0.5;
        }

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

        const material = new THREE.ShaderMaterial({
          vertexShader: `
            attribute float size;
            varying float vSize;
            void main() {
              vSize = size;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              gl_PointSize = size;
            }
          `,
          fragmentShader: `
            varying float vSize;
            uniform float time;
            void main() {
              float r = distance(gl_PointCoord, vec2(0.5));
              if (r > 0.5) discard;
              float brightness = sin(time * 2.0 + vSize * 10.0) * 0.5 + 0.5;
              gl_FragColor = vec4(1.0, 1.0, 1.0, brightness * (1.0 - r) * 0.5);
            }
          `,
          transparent: true,
          uniforms: {
            time: { value: 0 }
          }
        });

        return new THREE.Points(geometry, material);
      };

      const neuralNetworkLayers = [
        { name: "Input", color: new THREE.Color(0x4A90E2), particles: 50000, radius: 60 },
        { name: "Hidden Layer 1", color: new THREE.Color(0x50E3C2), particles: 75000, radius: 80 },
        { name: "Hidden Layer 2", color: new THREE.Color(0xF5A623), particles: 75000, radius: 80 },
        { name: "Hidden Layer 3", color: new THREE.Color(0xD0021B), particles: 75000, radius: 80 },
        { name: "Output", color: new THREE.Color(0x7ED321), particles: 50000, radius: 60 }
      ];

      const layerSpacing = 150;
      neuralNetworkLayers.forEach((layer, index) => {
        const galaxy = createGalaxy(layer.particles, layer.radius, layer.color, layer.name);
        galaxy.position.set((index - 2) * layerSpacing, 0, 0);
        galaxies.add(galaxy);

        if (index > 0) {
          const connection = createConnections(galaxies.children[index - 1], galaxy, 10000);
          connections.add(connection);
        }
      });

      stars = createStars(5000);

      scene.add(galaxies);
      scene.add(connections);
      scene.add(stars);

      const ambientLight = new THREE.AmbientLight(0x222222);
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0x6C3EFF, 2, 1000);
      pointLight.position.set(0, 0, 50);
      scene.add(pointLight);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      const time = clock.getElapsedTime();

      galaxies.children.forEach((galaxy, index) => {
        galaxy.material.uniforms.time.value = time;
        galaxy.rotation.y += 0.001 * (index + 1);
        galaxy.position.y = Math.sin(time * 0.2 + index) * 10;
      });

      connections.children.forEach((connection) => {
        connection.material.uniforms.time.value = time;
      });

      stars.material.uniforms.time.value = time;

      camera.position.x = Math.sin(time * 0.1) * 200;
      camera.position.y = Math.cos(time * 0.1) * 200;
      camera.position.z = Math.sin(time * 0.1 + Math.PI / 2) * 200 + 400;
      camera.lookAt(scene.position);

      composer.render();
    };

    init();
    animate();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      composer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      // Trim whitespace and remove any non-visible characters
      const cleanedApiKey = apiKey.trim().replace(/\s+/g, '');
      
      if (!/^[^:]+:[^:]+:[^:]+$/.test(cleanedApiKey)) {
        throw new Error('Invalid key format. Please ensure it follows the pattern: consumer_key:token_key:token_secret');
      }
      
      localStorage.setItem('maasApiKey', cleanedApiKey);
      setIsApiKeySet(true);
      await fetchMachines();
    } catch (error) {
      console.error('Error setting key:', error);
      if (error.message.includes('Invalid key format')) {
        setError(error.message);
      } else if (error.name === 'NetworkError' || error.message.includes('network')) {
        setError('Network error. Please check your internet connection and try again.');
      } else {
        setError(`Unexpected error: ${error.message}`);
      }
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <div ref={canvasRef} className="absolute inset-0 z-0"></div>
      <div className="absolute inset-0 z-10 bg-black bg-opacity-10 backdrop-blur-[0px] flex items-center justify-center">
        <div className="max-w-sm w-full p-4 bg-gray-900 bg-opacity-80 rounded-lg shadow-xl">
          <h1 className="text-2xl font-bold mb-4 tracking-wide title-animate text-center text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
            Compute Control Network
          </h1>
          <div className="space-y-3">
            <div className="flex items-center justify-center">
              <Key className="h-5 w-5 mr-2 text-gray-400 icon-animate drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]" />
              <span className="text-lg font-semibold text-gray-300 drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">Enter Access Key</span>
            </div>
            <form onSubmit={handleSubmit} className="space-y-3">
              <Input
                id="apiKey"
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                onPaste={(e) => {
                  e.preventDefault();
                  const pastedText = e.clipboardData.getData('text/plain').trim();
                  setApiKey(pastedText);
                }}
                placeholder="consumer_key:token_key:token_secret"
                className="bg-gray-800 bg-opacity-50 text-white border-gray-700 placeholder-gray-500 focus:border-gray-500 focus:ring-gray-500 text-sm"
                required
              />
              <GlowingButton type="submit">
                Activate Link
              </GlowingButton>
            </form>
            {error && (
              <Alert variant="destructive" className="bg-red-900 bg-opacity-50 border-red-700 text-red-200 text-sm">
                <AlertTriangle className="inline-block mr-2 h-4 w-4" />
                <span>{error}</span>
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyForm;
