// api.js

const API_BASE_URL = '/api';

const makeRequest = async (url, options, apiKey, isSync = false) => {
  const headers = {
    ...options.headers,
    'X-API-Key': apiKey,
    'X-Sync-Request': isSync ? 'true' : 'false',
    'ngrok-skip-browser-warning': '1'
  };
  
  if (process.env.NODE_ENV === 'development') {
    console.log('Making request to:', url);
  }
  
  try {
    const response = await fetch(url, { ...options, headers });
  
    if (!response.ok) {
      const errorText = await response.text();
      if (process.env.NODE_ENV === 'development') {
        console.error('API Error:', errorText);
      }
      throw new Error(`Request failed: ${response.status} ${response.statusText}. ${errorText}`);
    }
  
    const data = await response.json();
    if (process.env.NODE_ENV === 'development') {
      console.log('API Response:', sanitizeLogData(data));
    }
    return data;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Request error:', error);
    }
    throw error;
  }
};

// Helper function to sanitize log data
const sanitizeLogData = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => ({
      system_id: item.system_id,
      hostname: item.hostname,
      status_name: item.status_name,
      power_state: item.power_state
    }));
  } else if (typeof data === 'object' && data !== null) {
    return {
      system_id: data.system_id,
      hostname: data.hostname,
      status_name: data.status_name,
      power_state: data.power_state
    };
  }
  return data;
};

export const fetchMachines = async (apiKey, systemId = '', isSync = false) => {
  const url = systemId ? `${API_BASE_URL}/machines/${systemId}/` : `${API_BASE_URL}/machines/`;
  return makeRequest(url, { method: 'GET' }, apiKey, isSync);
};

export const handlePowerAction = async (apiKey, systemId, action) => {
  let endpoint;
  switch (action) {
    case 'power_on':
      endpoint = 'op-power_on';
      break;
    case 'power_off':
      endpoint = 'op-power_off';
      break;
    default:
      throw new Error(`Unsupported power action: ${action}`);
  }

  const url = `${API_BASE_URL}/machines/${systemId}/${endpoint}`;
  return makeRequest(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: '',  // Empty body as per API spec
  }, apiKey);
};

export const queryPowerState = async (apiKey, systemId) => {
  const url = `${API_BASE_URL}/machines/${systemId}/op-query_power_state`;
  return makeRequest(url, { method: 'GET' }, apiKey);
};

// Helper function to validate API key format
export const isValidApiKey = (apiKey) => {
  const parts = apiKey.split(':');
  return parts.length === 3 && parts.every(part => part.trim() !== '');
};

// Helper function to store API key in localStorage
export const storeApiKey = (apiKey) => {
  localStorage.setItem('maasApiKey', apiKey);
};

// Helper function to retrieve API key from localStorage
export const getStoredApiKey = () => {
  return localStorage.getItem('maasApiKey');
};

// Helper function to clear API key from localStorage
export const clearStoredApiKey = () => {
  localStorage.removeItem('maasApiKey');
};
