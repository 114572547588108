import React from 'react';
import { LogOut, RefreshCcw } from 'lucide-react';

const IconButton = ({ onClick, icon: Icon, color, className }) => (
  <button 
    onClick={onClick} 
    className="relative w-10 h-10 rounded-full overflow-hidden hover:opacity-80 transition-opacity duration-200"
  >
    <div className="absolute inset-0 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
    <div className="relative z-10 flex items-center justify-center w-full h-full">
      <Icon className={`h-5 w-5 ${color} ${className}`} />
    </div>
  </button>
);

const Header = ({ titleKey, handleLogout, fetchMachines }) => {
  return (
    <>
      <style>{`
        @keyframes spin-reverse {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }

        .animate-spin-reverse {
          animation: spin-reverse 10s linear infinite;
        }
      `}</style>
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <img 
            src="/ec_logo_icon.png" 
            alt="Elemental Compute Logo" 
            className="h-10 w-auto mr-2"
          />
          <h1 key={titleKey} className="text-3xl font-bold tracking-wide text-white">
            Compute Control
          </h1>
        </div>
        <div className="flex space-x-2">
          <IconButton 
            onClick={handleLogout} 
            icon={LogOut} 
            color="text-red-400" 
          />
          <IconButton 
            onClick={fetchMachines} 
            icon={RefreshCcw} 
            color="text-purple-400" 
            className="animate-spin-reverse"
          />
        </div>
      </div>
    </>
  );
};

export default Header;
